import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/layout/layout';
import Featured from '../components/featured';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, {
  location
}) => <Layout seoTitle="Website and Digital Home" description="The Digital Garden of Daniel (D.S.) Chapman filled with articles, poems, and notes on technology and the humanities." type="🌱" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "im-daniel--poet-writer-tinkerer"
    }}>{`I’m Daniel — Poet, Writer, Tinkerer`}</h1>
    <p>{`This site is a `}<a parentName="p" {...{
        "href": "/notes/web.digital-garden",
        "title": "web.digital-garden|digital garden"
      }}>{`[[web.digital-garden|digital garden]]`}</a>{` — an organic space that is meant to be explored as I work and tend it.`}</p>
    <p>{`Here you’ll find longer form `}<a parentName="p" {...{
        "href": "/articles"
      }}>{`articles`}</a>{`, `}<a parentName="p" {...{
        "href": "/poetry"
      }}>{`poems`}</a>{`, raw `}<a parentName="p" {...{
        "href": "/notes"
      }}>{`notes`}</a>{`, and the occasional `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`blog`}</a>{` post.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about"
        }}>{`About Me`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/poetry/seasons-of-thought"
        }}>{`My book of poetry`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://danielchapman.dev"
        }}>{`My Web Development Portfolio`}</a></li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/notes/me.toolbox",
          "title": "me.toolbox|My Toolbox"
        }}>{`[[me.toolbox|My Toolbox]]`}</a>{``}</li>
    </ul>
    <Featured mdxType="Featured" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      